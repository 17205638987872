import React from 'react'

function SubscribeList({ tag, title, description, checked, onChange }) {
  return (
    <div className="_row row-checkbox">
    <input 
      id={title} 
      type="checkbox" 
      name={title} 
      value={tag} 
      onChange={onChange} 
    />
    <label htmlFor={title}>
      <strong>{title}:</strong> {description}
    </label>
  </div>
  )
}

export default SubscribeList