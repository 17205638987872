import React from 'react';
import ReactDOM from 'react-dom/client';

import SubscribeForm from './SubscribeForm';

const subscribeDiv = document.getElementById('subscribe-form');
if (subscribeDiv !== null) {
  const selectDiv = ReactDOM.createRoot(subscribeDiv);
  selectDiv.render(
    <>
      <SubscribeForm />
    </>
  );  
}